import React from 'react';
import Img from 'gatsby-image';
import Image from '../elements/image';
import { FaPencilRuler, FaUser, FaList } from 'react-icons/fa';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const ProjectDetails = ({ data, location }) => {
  const { Cover, Name, Type, Role } = data.strapiProject;
  const toolsAndMethods = data.strapiProject.ToolsAndMethods.strapi_json_value;
  const Description = data.strapiProject.Description.data.childMarkdownRemark.rawMarkdownBody;
  const Images = data.strapiProject.Images;

  return (
    <Layout location={location}>
      <div className="rn-project-details-area rn-section-gap bg-color-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <div className="portfolio-content">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="content-left">
                        <div className="page-top">
                          <h1 className="title_holder">{Name}</h1>
                        </div>
                        <h3 className="mt--20">About the project</h3>
                        <ul className="list_holder">
                          <li>
                            <span className="icon">
                              <FaList />
                              Category:
                            </span>
                            <span className="projectinfo">{Type}</span>
                          </li>
                          <li>
                            <span className="icon">
                              <FaUser />
                              My Role:
                            </span>
                            <span className="projectinfo">{Role}</span>
                          </li>
                          <li>
                            <span className="icon">
                              <FaPencilRuler />
                              Tools and Methods:
                            </span>
                            <span className="projectinfo">{toolsAndMethods.join(', ')}</span>
                          </li>
                        </ul>
                        <ReactMarkdown className="project_description">{Description}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="thumbnail mt--90 mt_md--40 mt_sm--40">
                  {/* <Image */}
                  {/*   fluid={featured_image.childImageSharp.fluid} */}
                  {/* /> */}
                </div>

                <div className="image-group">
                  {Images &&
                    Array.isArray(Images) &&
                    Images.map((data, index) => {
                      return (
                        <div className="single-image mt--30" key={index}>
                          <Img fluid={data.localFile.childImageSharp.fluid} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// export const query = graphql`
//   query ($id: String!) {
//     projectJson(id: { eq: $id }) {
//       id
//       title
//       body
//       category
//       client
//       imgesBY
//       featured_image {
//         childImageSharp {
//           fluid(maxHeight: 1000, maxWidth: 1920, quality: 100) {
//             ...GatsbyImageSharpFluid_withWebp
//             presentationHeight
//             presentationWidth
//           }
//         }
//       }
//       features {
//         image {
//           childImageSharp {
//             fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
//               ...GatsbyImageSharpFluid_withWebp
//               presentationWidth
//               presentationHeight
//             }
//           }
//         }
//       }
//     }
//   }
// `;
export default ProjectDetails;

export const query = graphql`
  query ProjectQuery($id: String) {
    strapiProject(id: { eq: $id }) {
      Name
      Type
      Role
      ToolsAndMethods {
        strapi_json_value
      }
      Description {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      Cover {
        id
      }
      Images {
        localFile {
          childImageSharp {
            fluid {
              base64
              tracedSVG
              srcWebp
              srcSetWebp
              originalImg
              originalName
              src
              srcSet
              sizes
              presentationWidth
              presentationHeight
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
